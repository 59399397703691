// import React, { useEffect, useState } from 'react'
// import {
//   Box,
//   Stack,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableRow,
//   TableHead,
//   Paper,
//   TablePagination,
//   Button,
//   Grid
// } from '@mui/material'
// // import { PaginationSkeleton } from '../../assets/PaginationSkeleton'
// // import { RefressButton } from '../../assets/buttons/RefressButton'
// import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
// // import { asyncStatus } from '../../utils/async_status'

// import { IoMdShareAlt } from 'react-icons/io'
// import { setPDFdataState } from '../../../store/slices/layoutControler'
// // import { Show_Message_Center } from '../ShowMessage/showMessageCenter'

// const columns = [
//   {
//     id: 'serialNumber',
//     displayName: 'Username',
//     minWidth: 20,
//     align: 'center',
//     sortAction: false
//   },
//   {
//     id: 'user_email',
//     displayName: 'Name',
//     minWidth: 100,
//     align: 'center',
//     sortAction: true
//   },
//   {
//     id: 'name',
//     displayName: ' Service',
//     minWidth: 100,
//     align: 'center',
//     sortAction: true
//   },
//   {
//     id: 'email',
//     displayName: 'Email',
//     minWidth: 100,
//     align: 'center',
//     sortAction: true
//   },
//   {
//     id: 'currency',
//     displayName: 'Actions',
//     minWidth: 100,
//     align: 'center',
//     sortAction: true
//   },

//   // {
//   //   id: 'amount',
//   //   displayName: 'amount',
//   //   minWidth: 100,
//   //   align: 'center',
//   //   sortAction: true
//   // },
//   // {
//   //   id: 'balance',
//   //   displayName: 'Balance',
//   //   minWidth: 100,
//   //   align: 'center',
//   //   sortAction: true
//   // },
//   // {
//   //   id: 'Pay_Status',
//   //   displayName: 'Pay Status',
//   //   minWidth: 100,
//   //   align: 'center',
//   //   sortAction: true
//   // },
//   // {
//   //   id: 'view',
//   //   displayName: 'View Invoice',
//   //   minWidth: 100,
//   //   align: 'center',
//   //   sortAction: true
//   // },

// ]

// export const CustomTable = () => {
//   const dispatch = useDispatch()
//   const navigate = useNavigate()
//   // const { get_all_data_status, get_all_data_error, get_all_data ,pdf_local_data} = useSelector((state) => state.payment);

//   // useEffect(() => {

//   //   dispatch(get_all_data_async())

//   // }, [])

//   // const { data, status, error } = useSelector(state => state.getAdmission)

//   const { get_job_by_vendor_status, get_job_by_vendor_data, get_job_by_vendor_error } = useSelector((state) => state.serviceManage);

//   const [selectonchanges, setSelectonchanges] = useState()
//   const [courseId, setCourseId] = useState("")
//   const [batchid, setBatchid] = useState("")
//   const [rows, setRows] = useState([])
//   const [page, setPage] = React.useState(0)

//   const [rowsPerPage, setRowsPerPage] = React.useState(10)

//   // console.log({data});
//   // useEffect(() => {

//   //   if (status === asyncStatus.IDLE) {
//   //     dispatch(getAdmissionAsyncService())
//   //   }

//   // }, [])

//   useEffect(() => {
//     if (get_job_by_vendor_data?.length) {
//       setRows([...get_job_by_vendor_data])
//     }
//   }, [get_job_by_vendor_data])

//   // console.log("rows===========>>>>", rows)

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage)
//   }

//   const handleChangeRowsPerPage = event => {
//     setRowsPerPage(+event.target.value)
//     setPage(0)
//   }

//   // Modal open handle through add button

//   const [cardsOnclickHandle, setCardsOnclickHandle] = useState(null)

//   const valueset = (e) => {
//     setCardsOnclickHandle(e)
//     // dispatch(getAdmissionPdfAsyncService(e?._id))
//   }

//   const handleClick = text => {

//     setCourseId(text)
//   };
//   const handleClick2 = text => {

//     setBatchid(text)
//     // dispatch(getAdmissionByBatchAsyncService(text))

//   };
//   const [searchedInput, setSearchedInput] = useState("")
//   const [searchBy, setSearchBy] = useState("")

//   const veiwFunc = (elm) => {
//     console.log(elm)
//     dispatch(setPDFdataState(elm))
//     navigate("/local-pdf")
//   }

//   return (
//     <>

//       <Box>

//         <Stack>
//           <Paper sx={{ width: '100%' }}>
//             <TableContainer className='globleGradientBlue' sx={{ maxHeight: 700, borderRadius: "10px 10px 0px 0px", }}>
//               <Table stickyHeader aria-label='sticky table'>
//                 <TableHead className='globleGradientBlue'>
//                   <TableRow>
//                     {columns.map((column, i) => (
//                       <TableCell
//                         key={i}
//                         align={column.align}
//                         className='globleGradientBlue'
//                         style={{ minWidth: column.minWidth, color: "white", border: "none" }}
//                       >

//                         {column.displayName}

//                       </TableCell>
//                     ))}
//                   </TableRow>
//                 </TableHead>
//                 {
//                   // status === asyncStatus.LOADING ? (
//                   //   <TableBodyDataSkeleton numberOfColumns={columns.length} imagePreview={false} editAction={false} deleteAction={false} />
//                   // ) :
//                   rows ? (<TableBody>
//                     {rows
//                       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                       .map((row, i) => {
//                         return (<TableRow hover tabIndex={-1} key={i}>
//                           {columns.map((column, ind) => {
//                             return column.id === 'serialNumber' ? (
//                               <TableCell key={ind} align={column.align}>
//                                 {page < 1
//                                   ? i + 1
//                                   : page * rowsPerPage + (i + 1)}
//                               </TableCell>

//                             )
//                               : (
//                                 <TableCell key={ind} align={column.align}>
//                                   {/* {value} */}
//                                   {"value"}
//                                 </TableCell>
//                               )
//                           })}
//                         </TableRow>

//                         )
//                       })}
//                     {/* {!rows.length ? <Show_Message_Center lengthOfColumns={columns.length} message="No Admission Found" co /> : null} */}
//                   </TableBody>
//                   ) : null}
//               </Table>
//             </TableContainer>

//             {
//               // status === asyncStatus.LOADING ? (
//               //   <PaginationSkeleton />
//               // ) :
//               (
//                 <TablePagination
//                   rowsPerPageOptions={[10, 25, 100]}
//                   component='div'
//                   count={rows.length}
//                   rowsPerPage={rowsPerPage}
//                   page={page}
//                   onPageChange={handleChangePage}
//                   onRowsPerPageChange={handleChangeRowsPerPage}
//                 />
//               )}
//           </Paper>
//         </Stack>
//       </Box>
//     </>
//   )
// }

import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  Button,
  Grid,
} from "@mui/material";
// import { PaginationSkeleton } from '../../assets/PaginationSkeleton'
// import { RefressButton } from '../../assets/buttons/RefressButton'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { asyncStatus } from '../../utils/async_status'

import { IoMdShareAlt } from "react-icons/io";
import { setPDFdataState } from "../../../store/slices/layoutControler";
import CustomButton from "../Button/Button";
import { inputDateFormate } from "../../../utils/CustomFormator";
import { themeOrange } from "../../../utils/colorTheme";

const columns = [
  {
    id: "username",
    displayName: "Username",
    minWidth: 20,
    align: "center",
    sortAction: false,
  },
  // {
  //   id: 'name',
  //   displayName: 'Name',
  //   minWidth: 100,
  //   align: 'center',
  //   sortAction: true
  // },
  {
    id: "service",
    displayName: " Service",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },
  {
    id: "email",
    displayName: "Email",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },
  // {
  //   id: 'currency',
  //   displayName: 'Actions',
  //   minWidth: 100,
  //   align: 'center',
  //   sortAction: true
  // },

  {
    id: "amount",
    displayName: "amount",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },
  {
    id: "status",
    displayName: "Status",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },
  {
    id: "date",
    displayName: "Date",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },
  {
    id: "view",
    displayName: "Actions",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },
];

export const CustomTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { get_all_data_status, get_all_data_error, get_all_data ,pdf_local_data} = useSelector((state) => state.payment);

  const {
    get_job_by_vendor_status,
    get_job_by_vendor_data,
    get_job_by_vendor_error,
  } = useSelector((state) => state.serviceManage);
  // useEffect(() => {

  //   dispatch(get_all_data_async())

  // }, [])

  // const { data, status, error } = useSelector(state => state.getAdmission)

  const [selectonchanges, setSelectonchanges] = useState();
  const [courseId, setCourseId] = useState("");
  const [batchid, setBatchid] = useState("");
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [searchedInput, setSearchedInput] = useState("");
  const [searchBy, setSearchBy] = useState("");

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // console.log({data});
  // useEffect(() => {

  //   if (status === asyncStatus.IDLE) {
  //     dispatch(getAdmissionAsyncService())
  //   }

  // }, [])

  useEffect(() => {
    if (get_job_by_vendor_data?.length) {
      setRows([...get_job_by_vendor_data]);
    }
  }, [get_job_by_vendor_data]);

  // console.log("rows===========>>>>", rows)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Modal open handle through add button

  const [cardsOnclickHandle, setCardsOnclickHandle] = useState(null);

  const valueset = (e) => {
    setCardsOnclickHandle(e);
    // dispatch(getAdmissionPdfAsyncService(e?._id))
  };

  const handleClick = (text) => {
    setCourseId(text);
  };
  const handleClick2 = (text) => {
    setBatchid(text);
    // dispatch(getAdmissionByBatchAsyncService(text))
  };


  const veiwFunc = (elm) => {
    console.log(elm);
    // dispatch(setPDFdataState(elm));
    // navigate("/local-pdf");
  };

  return (
    <>
      <Box>
        <Stack>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{ maxHeight: 700, borderRadius: "10px 10px 0px 0px" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  className="globleGradientBlue"
                  sx={{
                    boxShadow: "none",
                  }}
                >
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={i}
                        align={column.align}
                        className="globleGradientBlue"
                        style={{
                          minWidth: column.minWidth,
                          color: "white",
                          border: "none",
                        }}
                      >
                        {column.displayName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {
                  // status === asyncStatus.LOADING ? (
                  //   <TableBodyDataSkeleton numberOfColumns={columns.length} imagePreview={false} editAction={false} deleteAction={false} />
                  // ) :
                  rows ? (
                    <TableBody>
                      {rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, i) => {
                          console.log("rowsssssss",row)
                          return (
                            <TableRow hover tabIndex={-1} key={i}>
                              {columns.map((column, ind) => {
                                const value = row[column.id];
                                const isCompleted =
                                  row[column.id] === "Completed";
                                const isAssigned =
                                  row[column.id] === "Assigned";
                                return column.id === "username" ? (
                                  <TableCell key={ind} align={column.align}>
                                    {/* {page < 1
                                      ? i + 1
                                      : page * rowsPerPage + (i + 1)} */}

                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      gap={2}
                                    >
                                      {/* <Stack
                                        sx={{
                                          borderRadius: "100%",
                                          height: "50px",
                                          width: "50px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <img
                                          src={tble_img_1}
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "containf",
                                          }}
                                        />
                                      </Stack> */}

                                      <Typography
                                        className="subpara"
                                        sx={{
                                          color: "#666262c2",
                                          lineHeight: "normal",
                                          fontSize: "16px !important",
                                          fontWeight: "400 !important",
                                        }}
                                      >
                                        {/* johnnc001 */}
                                        {`${row?.user?.first_name || ""} ${
                                          row?.user?.last_name || ""
                                        }`}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                ) : column.id === "name" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                        fontSize: "16px !important",
                                        fontWeight: "400 !important",
                                      }}
                                    >
                                      johnnc001
                                    </Typography>
                                  </TableCell>
                                ) : column.id === "service" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                        fontSize: "16px !important",
                                        fontWeight: "400 !important",
                                      }}
                                    >
                                      {row?.service?.name || ""}
                                    </Typography>
                                  </TableCell>
                                ) : column.id === "email" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                        fontSize: "16px !important",
                                        fontWeight: "400 !important",
                                      }}
                                    >
                                      {row?.user?.email || ""}
                                    </Typography>
                                  </TableCell>
                                ) : column.id === "amount" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                        fontSize: "16px !important",
                                        fontWeight: "400 !important",
                                      }}
                                    >
                                      {(+row?.service
                                        ?.price)?.toLocaleString() || ""}
                                    </Typography>
                                  </TableCell>
                                ) : column.id === "status" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      // className="subpara"
                                      sx={{
                                        backgroundColor: isCompleted
                                          ? "lightgreen"
                                          : isAssigned
                                          ? "#80008030"
                                          : "#f300002e",
                                        color: isCompleted
                                          ? "green"
                                          : isAssigned
                                          ? "purple"
                                          : "red",
                                        borderRadius: "10px",
                                        py: "4px",
                                        px: "10px",
                                      }}
                                    >
                                      {row?.status || ""}
                                    </Typography>
                                  </TableCell>
                                ) : column.id === "date" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                        fontSize: "16px !important",
                                        fontWeight: "400 !important",
                                      }}
                                    >
                                      {inputDateFormate(
                                        row?.date_time_lock.date || ""
                                      ) ?? ""}
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      <CustomButton
                                        onClick={() =>
                                          navigate(
                                            `/dashboard/job-done/${row?.id}`
                                          )
                                        }
                                        children={"View Details"}
                                      />
                                    </Typography>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      {/* {!rows.length ? <Show_Message_Center lengthOfColumns={columns.length} message="No Admission Found" co /> : null} */}
                    </TableBody>
                  ) : null
                }
              </Table>
            </TableContainer>

            {
              // status === asyncStatus.LOADING ? (
              //   <PaginationSkeleton />
              // ) :
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            }
          </Paper>
        </Stack>
      </Box>
    </>
  );
};
