import React, { useEffect, useState } from "react";
import { themeOrange } from "../../utils/colorTheme";
import Stepper from "react-stepper-js";
import "react-stepper-js/dist/index.css";
import {
  Divider,
  Stack,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import "./index.css";
import CustomAccordion from "../common/customAccordion/CustomAccordion";
import { MdLibraryAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setJobsSteps } from "../../store/slices/ui_control_slice";
import { FiUpload, FiX } from "react-icons/fi";
import { setJobsImages } from "../../store/slices/services_slice";
import { service_booking_keys } from "../../store/constants";
import { ErrorMessage, Form, Formik } from "formik";
import ButtonComp from "../common/ButtonComp";
import { getValidationSchema } from "../../pages/auth/ValidationsFormikYup/ValidationsSchema";
import { useParams } from "react-router-dom";
import { save_tokens_constant } from "../../utils/asyncStatus";
import uploadImage from "../../utils/ImageUploader";
import axios from "axios";

const serviceDetailContent = [
  {
    id: "appliance-repair",
    points: [
      `Appliances not cooling, heating, or working correctly, leading to uneven performance or failure to operate.`,
      `Leaks, unusual noises, or excessive vibrations during use, affecting reliable operation.`,
      `Control panel malfunctions, error codes, and broken components like doors or turntables.`,
    ],
  },
  {
    id: "bbq-cleaning-repair",
    points: [
      `Thick grease, caked-on residue, and rust on grates and burners, impacting grilling performance and safety.`,
      `Ignition and burner issues, such as clogged ports, faulty igniters, and uneven flames, requiring repair.`,
      `Gas leaks, faulty valves, and fluctuating temperatures due to worn regulators, posing safety hazards and affecting temperature control.`,
    ],
  },
  {
    id: "carpet-upholstery-cleaning",
    points: [
      `Removing deep-set stains, pet odors, and embedded dirt to restore freshness and cleanliness.`,
      `Eliminating allergens like dust, pollen, and pet dander for improved indoor air quality.`,
      `Revitalizing carpets and upholstery by restoring color and texture, prolonging their lifespan.`,
    ],
  },
  {
    id: "duct-cleaning",
    points: [
      `Removing dust, allergens, and debris to improve indoor air quality and reduce respiratory issues.`,
      `Eliminating mold and mildew growth to prevent odors and health risks.`,
      `Enhancing HVAC efficiency for better airflow, temperature control, and lower energy bills.`,
    ],
  },
  {
    id: "electrical",
    points: [
      `Preventing circuit breaker trips and power surges to protect appliances and ensure stable power.`,
      `Addressing flickering lights, warm or sparking outlets, and buzzing sounds to reduce fire hazards.`,
      `Upgrading outdated wiring and panels to enhance safety and accommodate modern electrical demands.`,
    ],
  },
  {
    id: "junk-removal",
    points: [
      `Comprehensive junk removal for cluttered spaces, leftover items from moves, or tenant turnover.`,
      `Specialized disposal for hazardous materials, e-waste, and items requiring eco-friendly recycling.`,
      `Efficient removal of bulky items and debris, even in hard-to-reach or challenging areas.`,
    ],
  },
  {
    id: "decks-fences",
    points: [
      `Repairing decayed or insect-damaged wood to maintain strength and safety.`,
      `Fixing loose railings, leaning posts, and misaligned gates to ensure stability and usability.`,
      `Restoring surfaces affected by fading, peeling, or mold growth for improved appearance and durability.`,
    ],
  },
  {
    id: "furniture-assembly",
    points: [
      `Ensuring all parts fit securely and align properly for stable, durable assembly.`,
      `Providing support with heavy or complex pieces to prevent damage and ease setup.`,
      `Using necessary tools and clear instructions to speed up the process and avoid frustration.`,
    ],
  },
  {
    id: "garage-door-repair",
    points: [
      `Fixing common issues like doors not opening or closing properly, getting stuck, or reversing unexpectedly.`,
      `Repairing and replacing worn-out parts such as springs, cables, rollers, and sensors to ensure smooth operation.`,
      `Addressing problems with the opener, remote control, and wall switch for consistent and responsive functionality.`,
    ],
  },
  {
    id: "gas-services",
    points: [
      `Detecting and repairing gas leaks, unusual odors, or hissing sounds to ensure safety.`,
      `Resolving appliance issues like pilot light outages, ignition problems, and inconsistent heating.`,
      `Replacing or repairing damaged gas lines, fittings, and seals to improve efficiency and prevent unexpected shutdowns.`,
    ],
  },
  {
    id: "lawn-maintenance",
    points: [
      `Addressing uneven growth, bare patches, and pest damage to promote healthy grass.`,
      `Regular maintenance for equipment like mowers and trimmers to ensure clean, precise cuts.`,
      `Effective weed and pest control, along with proper irrigation and fertilization, to maintain a lush, green lawn.`,
    ],
  },
  {
    id: "heating-cooling-hvac",
    points: [
      `Resolving issues like inefficient heating or cooling and poor airflow to improve comfort.`,
      `Fixing thermostat malfunctions, frequent cycling, and unusual noises for smoother operation.`,
      `Addressing refrigerant leaks, air filter blockages, and humidity control for optimal air quality and system efficiency.`,
    ],
  },
  {
    id: "pest-control-annual-check-up",
    points: [
      `Early detection and treatment of pest infestations, including termites and seasonal pests.`,
      `Identifying structural vulnerabilities and entry points for rodents and other pests.`,
      `Preventive measures and monitoring of areas prone to ant trails, mosquito, and tick breeding.`,
    ],
  },
  {
    id: "locksmith",
    points: [
      `Emergency lockout assistance for homes, offices, and cars.`,
      `Repairing or replacing faulty locks and keys, including rekeying services.`,
      `Installing high-security and electronic locks for enhanced safety and access control.`,
    ],
  },
  {
    id: "painting",
    points: [
      `Ensuring smooth, even coverage and consistent finishes for a professional look.`,
      `Addressing peeling, cracking, and fading issues for long-lasting color.`,
      `Proper surface preparation to prevent dirt, moisture, and paint bleed on trims and edges.`,
    ],
  },
  {
    id: "pest-control",
    points: [
      `Effective elimination of common infestations, including ants, cockroaches, and spiders.`,
      `Specialized control for rodents, termites, and bed bugs to prevent property damage.`,
      `Safe removal of stinging insect nests and control of outdoor pests like mosquitoes and ticks.`,
    ],
  },
  {
    id: "powerwash-stain-seal",
    points: [
      `Removing dirt, grime, and mold buildup for a clean and prepared surface.`,
      `Applying stain evenly to prevent discoloration, fading, and moisture issues.`,
      `Proper sealing to protect wood surfaces from water damage and ensure long-lasting results.`,
    ],
  },
  {
    id: "window-eaves-cleaning",
    points: [
      `Removing streaks, smudges, and hard water stains for spotless, clear windows.`,
      `Clearing debris and clogs in gutters, downspouts, and eaves to prevent water buildup.`,
      `Eliminating mold, mildew, and insect nests for a clean, well-maintained exterior.`,
    ],
  },
  {
    id: "roofing",
    points: [
      `Repairing leaks, water damage, and missing shingles to ensure a watertight roof.`,
      `Addressing ventilation issues and sagging sections for structural integrity and efficiency.`,
      `Preventing damage from ice dams, pests, and worn flashing around vents and chimneys.`,
    ],
  },
  {
    id: "plumbing",
    points: [
      `Fixing leaky faucets, pipes, and toilets to prevent water waste and damage.`,
      `Clearing clogged drains and addressing sewer line backups for smooth water flow.`,
      `Resolving issues with water heaters, low water pressure, and frozen pipes for reliable performance.`,
    ],
  },
  {
    id: "moving-delivery",
    points: [
      `Safe transport of heavy, bulky, and fragile items with protection against damage.`,
      `Professional handling of disassembly, reassembly, and navigating tight spaces.`,
      `Efficient coordination of timing, scheduling, and temporary storage for a smooth move.`,
    ],
  },
  {
    id: "seasonal-lawn-maintenance",
    points: [
      `Seasonal cleanup, including debris removal, weed control, and leaf management for a healthy lawn year-round.`,
      `Soil care with aeration, fertilizing, and mowing adjustments tailored to each season.`,
      `Preparing the lawn for weather changes, from drought management in summer to winterizing for dormancy.`,
    ],
  },
  {
    id: "handyman-services",
    points: [
      `Performing minor repairs like leaky faucets, drywall patches, and door adjustments.`,
      `Installing fixtures, ceiling fans, and assembling furniture for home convenience.`,
      `Mounting shelves, TVs, and handling caulking or sealing to maintain a polished space.`,
    ],
  },
  {
    id: "mulch-area",
    points: [
      `Preventing weed growth and maintaining consistent mulch coverage to protect plants.`,
      `Managing mulch erosion and soil compaction for a stable, healthy ground cover.`,
      `Addressing mold, pests, and over-mulching to ensure a vibrant, well-maintained mulch area.`,
    ],
  },
  {
    id: "organizing-decluttering-packing",
    points: [
      `Reducing clutter and organizing living spaces to create a functional, stress-free environment.`,
      `Assisting with decisions on what to keep, discard, or store, especially for downsizing.`,
      `Providing efficient packing and storage solutions, including seasonal items and important documents.`,
    ],
  },
  {
    id: "ac-tune-up",
    points: [
      `Cleaning or replacing dirty air filters and condenser coils to improve airflow and efficiency.`,
      `Checking refrigerant levels and thermostat calibration for optimal cooling performance.`,
      `Inspecting ductwork for leaks and addressing issues like short cycling and strange noises.`,
    ],
  },
  {
    id: "artificial-turf",
    points: [
      `Preventing weed growth, moss, and algae to keep turf looking fresh and clean.`,
      `Addressing drainage, infill compaction, and loose sections for a stable, well-maintained surface.`,
      `Managing fading, pet odors, and heat retention for durability and comfort year-round.`,
    ],
  },
  {
    id: "bathtub-shower-caulking",
    points: [
      `Replacing cracked, peeling, or discolored caulk to maintain a clean, waterproof seal.`,
      `Sealing gaps and preventing mold or mildew growth for a healthier bathroom environment.`,
      `Ensuring strong adhesion and durability of caulk to protect against water leakage over time.`,
    ],
  },
  {
    id: "smart-home-install",
    points: [
      `Resolving compatibility and connectivity issues for seamless device integration.`,
      `Ensuring secure setup and addressing privacy concerns for a safe smart home environment.`,
      `Assisting with app setup, automation, and firmware updates to keep devices functioning smoothly.`,
    ],
  },
  {
    id: "sod-installation",
    points: [
      `Preparing soil properly and placing sod evenly for a smooth, seamless lawn.`,
      `Ensuring proper watering and sunlight exposure to support root establishment.`,
      `Managing weed, pest, and disease prevention to maintain healthy, lush sod.`,
    ],
  },
  {
    id: "stone-interlock-concrete",
    points: [
      `Repairing uneven surfaces, loose pavers, and addressing settling for a stable foundation.`,
      `Preventing weed growth, cracking, and efflorescence for a clean, durable finish.`,
      `Managing drainage issues, stains, and color fading to maintain appearance and longevity.`,
    ],
  },
  {
    id: "tile-grout-cleaning",
    points: [
      `Removing stubborn stains, grease, and soap scum for a fresh, clean surface.`,
      `Eliminating mold, mildew, and efflorescence to improve hygiene and appearance.`,
      `Repairing damaged grout and resealing to protect against wear and ensure long-lasting results.`,
    ],
  },
  {
    id: "tree-services",
    points: [
      `Pruning, trimming, and managing overgrown branches to maintain tree health and safety.`,
      `Removing dead, hazardous trees and grinding stumps to clear space and prevent accidents.`,
      `Providing disease, pest control, and root management to protect tree vitality and nearby structures.`,
    ],
  },
  {
    id: "tv-mounting",
    points: [
      `Finding wall studs and securely mounting TVs for stability and safety.`,
      `Managing cables and choosing the right mount for clean installation and proper support.`,
      `Ensuring ideal viewing angle and integrating with other devices for an optimal setup.`,
    ],
  },
  {
    id: "wasp-treatment",
    points: [
      `Safely locating and removing active wasp nests, even in hard-to-reach areas.`,
      `Using effective control products to handle aggressive species while minimizing risks to pets and children.`,
      `Implementing preventative measures to avoid reinfestation and ensure long-term outdoor protection.`,
    ],
  },
  {
    id: "water-sewage-damage",
    points: [
      `Extracting water, drying affected areas, and preventing mold and mildew growth.`,
      `Assessing structural damage, replacing drywall and flooring, and repairing electrical systems.`,
      `Thoroughly cleaning, decontaminating, and addressing sewage contamination to restore a safe environment.`,
    ],
  },
  {
    id: "window-eaves-cleaning",
    points: [
      `Removing dirt, grime, and hard water stains for streak-free windows.`,
      `Clearing debris, mold, and insect nests from gutters, eaves, and downspouts to prevent clogs.`,
      `Addressing seasonal buildup and hard-to-reach areas for a thorough, lasting clean.`,
    ],
  },
  {
    id: "yard-bag-delivery",
    points: [
      `Providing timely delivery and convenient drop-off for seasonal yard projects.`,
      `Offering durable, weather-resistant bags sized to handle heavy and large loads.`,
      `Ensuring easy scheduling for bag collection, with recycling and disposal options available.`,
    ],
  },
  {
    id: "yard-clean-up",
    points: [
      `Removing leaves, debris, and fallen branches to keep the yard tidy and safe.`,
      `Clearing overgrown weeds, trimming shrubs, and maintaining flower beds for a polished look.`,
      `Preparing the yard for seasonal changes with lawn mowing, edging, and green waste disposal.`,
    ],
  },
  {
    id: "deep-cleaning",
    points: [
      `Removing built-up grease, grime, and persistent odors for a refreshed environment.`,
      `Sanitizing high-touch surfaces and eliminating mold and mildew for improved hygiene.`,
      `Thoroughly cleaning hard-to-reach areas, carpets, and upholstery for a complete deep clean.`,
    ],
  },
  {
    id: "dishwasher-installation",
    points: [
      `Ensuring secure water and electrical connections to prevent leaks and ensure safety.`,
      `Properly leveling and fitting the dishwasher for stability and a seamless cabinet fit.`,
      `Testing for proper operation and adhering to local plumbing and electrical codes for compliance.`,
    ],
  },
  {
    id: "dryer-vent-cleaning",
    points: [
      `Removing lint buildup and blockages to prevent fire hazards and improve dryer efficiency.`,
      `Clearing dust, debris, and obstructions to ensure proper ventilation flow and reduce drying time.`,
      `Preventing mold growth and animal nesting to maintain air quality and reduce energy costs.`,
    ],
  },
  {
    id: "drywall-repair",
    points: [
      `Patching holes, cracks, and dents to restore a smooth wall surface.`,
      `Repairing water-damaged areas and addressing uneven or bulging sections for durability.`,
      `Matching textures and preparing walls for painting to achieve a seamless finish.`,
    ],
  },
  {
    id: "eavestrough-flashing-siding-repair",
    points: [
      `Fixing leaks, sealing gaps, and replacing rusted sections to prevent water infiltration.`,
      `Repairing storm damage, loose siding, and damaged flashing for durable protection.`,
      `Clearing clogs and addressing water pooling to ensure proper drainage and prevent overflow.`,
    ],
  },
  {
    id: "exterior-caulking",
    points: [
      `Sealing cracks, gaps, and deteriorating caulk to prevent water leaks and moisture seepage.`,
      `Addressing air drafts to improve energy efficiency and reduce heating/cooling costs.`,
      `Removing moldy or discolored caulk and ensuring a consistent application for a clean appearance.`,
    ],
  },
  {
    id: "flooring",
    points: [
      `Repairing scratches, surface damage, and discoloration to restore flooring appearance.`,
      `Fixing water damage, loose tiles, and warped sections for a stable, durable floor.`,
      `Addressing gaps, squeaks, and lifting areas to maintain smooth, even flooring.`,
    ],
  },
  {
    id: "furnace-tune-up",
    points: [
      `Cleaning filters and clearing airflow issues to improve heating efficiency.`,
      `Inspecting and fixing pilot light, ignition, and thermostat malfunctions for reliable operation.`,
      `Addressing worn-out parts and strange noises to prevent breakdowns and reduce energy consumption.`,
    ],
  },
  {
    id: "gas-fireplace-tune-up",
    points: [
      `Resolving ignition and pilot light issues for consistent, safe operation.`,
      `Cleaning burners, logs, and glass to prevent soot buildup and ensure a strong flame.`,
      `Checking for gas leaks, inspecting vents, and maintaining gaskets for safe and efficient heating.`,
    ],
  },
  {
    id: "appliance-install",
    points: [
      `Ensuring secure electrical and water connections with proper ventilation for safe operation.`,
      `Positioning and leveling appliances to fit seamlessly into the existing space.`,
      `Removing old appliances and adhering to local codes for a compliant installation.`,
    ],
  },
];

const SecondStep = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [cancelToken] = useState(axios.CancelToken.source());
  const {
    get_service_by_id_status,
    get_service_by_id_data,
    get_service_by_id_error,
    jobs_images,
  } = useSelector((state) => state.serviceManage);
  const [loading, setLoading] = useState(false); // Loading state for image upload

  const _backHandle = () => {
    dispatch(setJobsSteps({ value: "1" }));
  };

  const _handleNext = (values) => {
    // dispatch(setJobsImages(values.images));
    dispatch(setJobsSteps({ value: "3" }));
    localStorage.setItem(service_booking_keys.DESCRIPTION, values.description);
    localStorage.setItem(
      service_booking_keys.IMAGES,
      JSON.stringify(values.images)
    );
  };

  const _handleUploadClick = () => {
    document.getElementById("upload-input").click();
  };

  const _handleDeleteClick = (index, values, setFieldValue) => {
    // Remove the image and its caption from the images array
    const newImages = values.images.filter((_, i) => i !== index);
    setFieldValue("images", newImages); // Update Formik's values
  };

  const authToken = localStorage.getItem(save_tokens_constant.AUTH);

  const _handleFileChange = async (e, setFieldValue, values) => {
    const newFiles = Array.from(e.target.files);

    for (const file of newFiles) {
      try {
        setLoading(true);
        const url = await uploadImage(
          file,
          "https://trailer-backend-5c9a7f112e54.herokuapp.com/upload-files", // Replace with your endpoint
          setLoading,
          "binary_file", // File key
          authToken, // Token
          cancelToken
        );

        const newImageObject = { url, caption: "" };
        setFieldValue("images", [...values.images, newImageObject]); // Update Formik values
      } catch (error) {
        console.error("Image upload failed:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _handleCaptionChange = (e, index, setFieldValue, values) => {
    const updatedImages = values.images.map((image, i) =>
      i === index ? { ...image, caption: e.target.value } : image
    );
    setFieldValue("images", updatedImages);
  };


  const specificServiceContent = serviceDetailContent?.filter(
    (point) => point?.id === id
  );

  // Load initial values from localStorage
  const initialValues = {
    description: localStorage.getItem(service_booking_keys.DESCRIPTION) || "",
    images: JSON.parse(localStorage.getItem(service_booking_keys.IMAGES)) || [],
  };

  return (
    <div>
      <Stack>
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationSchema("third_step")}
          onSubmit={_handleNext}
          enableReinitialize
        >
          {({ errors, touched, setFieldValue, values, handleSubmit }) => (
            <Form>
              <Stack className="service-name">
                {get_service_by_id_data?.service?.name}
              </Stack>
              <Stack
                className="overflow_stepper"
                sx={{
                  overflow: "auto",
                }}
              >
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  className="stepperClass"
                  sx={{
                    minWidth: "260px",
                  }}
                >
                  <Stepper
                    color={themeOrange}
                    fontSize="12px"
                    fontColor="black"
                    steps={[
                      { label: "SCHEDULE" },
                      { label: "JOB DETAILS" },
                      { label: "CONFIRM" },
                    ]}
                    currentStep={2}
                  />
                </Stack>
              </Stack>

              <Divider sx={{ marginTop: "50px" }} />
              <Typography mt={2} className="service-sub-name">
                What do you need done?
              </Typography>
              {
                <Stack style={{ marginTop: "32px" }}>
                  <CustomAccordion
                    title="Pros recommend including these details:"
                    content={
                      <div>
                        <ul>
                          {specificServiceContent[0]?.points?.map((point) => {
                            return (
                              <li style={{ fontSize: "15px" }}>
                                {point || ""}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    }
                    marginLeft="30px"
                  />
                </Stack>
              }

              <Stack mt={2}>
                <textarea
                  id={"description"}
                  rows="4"
                  cols="50"
                  placeholder="e.g.something that needs to be fixed, installed, cleaned, etc."
                  value={values.description}
                  onChange={(e) => {
                    setFieldValue("description", e.target.value);
                  }}
                  className="time_contraints_inp"
                  style={{
                    borderColor:
                      touched.description && errors.description
                        ? "red"
                        : themeOrange,
                    borderRadius: "10px",
                    padding: "12px 20px",
                    resize: "none",
                  }}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error-message"
                />
              </Stack>

              <Divider sx={{ marginTop: "50px" }} />
              <Typography mt={2} className="service-sub-name">
                Upload Photos
              </Typography>
              {/* <Stack sx={{ marginTop: "32px" }}>
                <CustomAccordion
                  title="Pros recommend including these details:"
                  content={
                    <div>
                      <ul style={{ listStyleType: "none" }}>
                        <li>Pic of the appliance(s)</li>
                        <li>
                          Pics of where the appliance(s) will be installed
                        </li>
                        <li>Pics of the label showing model name and number</li>
                        <li>
                          Pics of any parts or materials you are providing
                        </li>
                      </ul>
                      <div style={{ marginLeft: "-30px", marginTop: "10px" }}>
                        These details are not needed if you are removing old
                        appliances.
                      </div>
                    </div>
                  }
                  marginLeft="30px"
                />
              </Stack> */}
              <Stack
                sx={{ marginTop: "32px" }}
                className="upload-image-container"
              >
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    // height: "100%",
                    // border: selectedImages ? "0.5px solid white" : "none",
                    borderRadius: "10px",
                    p: 1.5,
                    cursor: "pointer",
                    position: "relative",
                    // width: "100%",
                  }}
                  gap={2}
                  onClick={_handleUploadClick}
                >
                  <input
                    id="upload-input"
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) =>
                      _handleFileChange(e, setFieldValue, values)
                    }
                    style={{ display: "none" }}
                  />
                  <MdLibraryAdd size={25} color={themeOrange} />
                  <Stack className="add-photo-title">Add Photos</Stack>
                </Stack>
              </Stack>
              <ErrorMessage
                name="images"
                component="div"
                className="error-message"
              />

              <Stack sx={{ width: "100%" }}>
                {loading && (
                  <Stack
                    my={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      height: "100px",
                      width: "100px",
                      backgroundColor: "#d4d1d1",
                      overflow: "hidden",
                      borderRadius: "10px",
                      position: "relative",
                      flexShrink: "0",
                    }}
                  >
                    <CircularProgress size={20} sx={{ color: themeOrange }} />
                  </Stack>
                )}
                <Stack
                  // direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  my={1}
                  sx={{ flex: 1 }}
                >
                  {values.images.length > 0 &&
                    values.images.map((imageObj, i) => {
                      return (
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{ width: "100%" }}
                          gap={2}
                        >
                          <Stack
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{
                              height: "100px",
                              width: "100px",
                              backgroundColor: "#d4d1d1",
                              overflow: "hidden",
                              borderRadius: "10px",
                              position: "relative",
                              flexShrink: "0",
                            }}
                          >
                            <img
                              src={imageObj?.url}
                              alt={`Uploaded ${i}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "10px",
                              }}
                            />
                            {/* Delete button */}
                            <IconButton
                              onClick={() =>
                                _handleDeleteClick(i, values, setFieldValue)
                              }
                              sx={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                color: "white",
                                zIndex: 1,
                                padding: "3px",
                                fontSize: "15px",
                                backgroundColor: "white",
                              }}
                            >
                              <FiX
                                style={{ color: "grey", fontSize: "15px" }}
                              />
                            </IconButton>
                          </Stack>
                          <Stack sx={{ flex: 1 }}>
                            <textarea
                              id={`caption-${i}`}
                              rows="4"
                              // cols="50"
                              placeholder="Add a short caption (Optional)"
                              value={imageObj.caption}
                              onChange={(e) => {
                                // setFieldValue("image_caption", e.target.value);
                                _handleCaptionChange(
                                  e,
                                  i,
                                  setFieldValue,
                                  values
                                );
                              }}
                              className="time_contraints_inp"
                              defaultValue={imageObj.caption}
                              style={{
                                borderColor:
                                  touched.image_caption && errors.image_caption
                                    ? "red"
                                    : themeOrange,
                                borderRadius: "10px",
                                padding: "12px 20px",
                                resize: "none",
                                width: "100%",
                              }}
                            />
                          </Stack>
                        </Stack>
                      );
                    })}
                </Stack>
              </Stack>

              <Divider sx={{ marginTop: "10px" }} />
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                gap={2}
                mb={2}
                mt={2}
              >
                <Stack
                  onClick={_backHandle}
                  className="next-btn-outline"
                  style={{ marginTop: "0px " }}
                >
                  Back
                </Stack>
                <ButtonComp
                  onClick={handleSubmit}
                  type="submit"
                  label={"Next"}
                  style={{
                    width: "40%",
                    borderRadius: "20px",
                    background:
                      "linear-gradient(180deg, #F15A24 0%, #C53F10 100%)",
                    margin: "0px 0px",
                  }}
                />
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </div>
  );
};

export default SecondStep;
