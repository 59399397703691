import React, { useEffect, useState } from "react";
import { themeBlue, themeOrange } from "../../utils/colorTheme";
import Stepper from "react-stepper-js";
import "react-stepper-js/dist/index.css";
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import serName from "../../assets/serviceName.png";
import check from "../../assets/check.png";
import { FaCalendarDays } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { IoDocumentTextSharp } from "react-icons/io5";
import { MdLock } from "react-icons/md";
import CreditCardForm from "../CreditCard/CardInput";
import { LuCalendarClock, LuCreditCard } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";
import { get_service_by_id, jobPostAsync } from "../../services/services";
import {
  setBackStepFirst,
  setJobsSteps,
} from "../../store/slices/ui_control_slice";
import { asyncStatus } from "../../utils/asyncStatus";
import {
  setJobPostStatus,
  setJobsImages,
} from "../../store/slices/services_slice";
import {
  service_booking,
  service_booking_keys,
  SERVICE_DETAIL_PAGE_CONSTANT,
  steps_constants,
} from "../../store/constants";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "../../index.css";
import CustomModal from "../common/CustomModal/CustomModal";
import Input from "../common/Input";
import IconInput from "../IconInput/IconInput";
import CustomLocationSearch from "../IconInput/customInput";
import { formatDate, inputDateFormate } from "../../utils/CustomFormator";
import { BiSolidLock } from "react-icons/bi";
import secure_check from "../../assets/secure_check.png";
import { FiInfo } from "react-icons/fi";
import { error_toast_message } from "../../utils/toast_message";
import { format } from "date-fns";
import ButtonComp from "../common/ButtonComp";
import { MdOutlineWatchLater } from "react-icons/md";
import { common_caption_strings } from "../../utils/language_controls/constant_strings";

// testing key
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const LastStep = () => {
  const { id } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCardComplete, setIsCardComplete] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    post_job_service_status,
    post_job_service_data,
    post_job_service_error,
    get_service_by_id_status,
    get_service_by_id_data,
    jobs_images,
  } = useSelector((state) => state.serviceManage);
  const { user, userAuth } = useSelector((state) => state.userAuth);

  const jobPostLoader = post_job_service_status === asyncStatus.LOADING;

  const date = JSON.parse(localStorage.getItem(service_booking_keys.DATE));
  const time = localStorage.getItem(service_booking_keys.TIME);
  const desc = localStorage.getItem(service_booking_keys.DESCRIPTION);
  const location = localStorage.getItem(service_booking_keys.LOCATION);
  const price = localStorage.getItem(service_booking_keys.PRICE);
  const images = JSON.parse(localStorage.getItem(service_booking_keys.IMAGES));

  // useEffect(() => {
  //   dispatch(get_service_by_id(id));
  // }, []);

  useEffect(() => {
    if (post_job_service_status === asyncStatus.SUCCEEDED) {
      navigate("/dashboard/myjobs-user");
      dispatch(setJobsSteps({ value: null }));
      localStorage.removeItem(steps_constants.JOBS_STEP);
      dispatch(setJobsImages([]));
      localStorage.removeItem(service_booking_keys.DATE);
      localStorage.removeItem(service_booking_keys.TIME);
      localStorage.removeItem(service_booking_keys.DESCRIPTION);
      localStorage.removeItem(service_booking_keys.LOCATION);
      localStorage.removeItem(service_booking_keys.PRICE);
      localStorage.removeItem(service_booking_keys.IMAGES);
      dispatch(setJobPostStatus());
    }
  }, [post_job_service_status, navigate]);

  const _handleCancelJob = () => {
    dispatch(setJobsSteps({ value: null }));
    localStorage.removeItem(steps_constants.JOBS_STEP);
    // navigate(`/dashboard/myjobs-user`);
    localStorage.removeItem(service_booking_keys.DATE);
    localStorage.removeItem(service_booking_keys.TIME);
    localStorage.removeItem(service_booking_keys.DESCRIPTION);
    localStorage.removeItem(service_booking_keys.LOCATION);
    localStorage.removeItem(service_booking_keys.PRICE);
    localStorage.removeItem(service_booking_keys.IMAGES);
  };

  // const handleCardChange = (event) => {
  //   setIsCardComplete(event.complete);
  // };

  const handleCardChange = (event) => {
    setIsCardComplete(event.complete);
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // CardElement se card details extract karna
    const cardElement = elements.getElement(CardElement);

    // Stripe ka token generate karna
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      console.error("Error:", error);
      error_toast_message(error?.message);
    } else {
      const jobPostObj = {
        [service_booking_keys.LOCATION]: location,
        [service_booking_keys.SERVICE]: id,
        [service_booking_keys.DESCRIPTION]: desc,
        [service_booking_keys.PRICE]: price,
        [service_booking_keys.IMAGES]: images,
        [service_booking_keys.DATE]: date,
        [service_booking_keys.TIME]: time,
        [service_booking_keys.STRIPETOKEN]: token.id,

        //   "date": [
        //     {
        //       "selected_date": "2024-12-22T19:00:00.000Z",
        //       "shifts": ["Morning", "Afternoon", "Evening"]
        //     }
        //   ],
        //   "service":"appliance-repair",
        //   "description": "test",
        //   "images": [
        //     {
        //       "caption": "captions...",
        //       "url": "image_url"
        //     }
        //   ],
        //   "location": "65 Front St W, Toronto, ON M5J 1E6, Canada",
        //   "price": "member",
        //   "stripeToken": "tok_1QZIWX07SeeusJqERL5Txp6d",
        //   "time": "time constraints..."
      };
      // const formData = new FormData();
      // formData.append(service_booking_keys.LOCATION, location);
      // formData.append(service_booking_keys.SERVICE, id);
      // // formData.append(service_booking_keys.DATE, date);
      // formData.append(service_booking_keys.DESCRIPTION, desc);
      // formData.append(service_booking_keys.PRICE, price);

      // images.forEach((image, index) => {
      //   formData.append(`${service_booking_keys.IMAGES}[]`, image);
      // });
      // date.forEach((date, index) => {
      //   formData.append(`${service_booking_keys.DATE}[]`, date);
      // });
      console.log("data", {
        location: location,
        id: id,
        date: date,
        desc: desc,
        price: price,
        images: images,
        stripTokern: token.id,
        time: time,
      });
      // formData.append(service_booking_keys.TIME, time);
      // formData.append(service_booking_keys.STRIPETOKEN, token.id);
      dispatch(jobPostAsync(jobPostObj));
    }
  };

  const handleEdit = () => {
    dispatch(setJobsSteps({ value: "2" }));
  };

  function _addThirteenPercent(value) {
    const percentage = value * 0.13; // Calculate 13% of the value
    const total = value + percentage; // Add 13% to the original value
    return total;
  }

  const servicePrice = +get_service_by_id_data?.service?.price;
  const hst =
    price !== "user" ? (servicePrice - 25) * 0.13 : servicePrice * 0.13;
  const formattedTotalPrice = hst.toLocaleString();

  return (
    <div>
      <Stack>
        <Stack className="service-name">
          {get_service_by_id_data?.service?.name}
        </Stack>

        <Stack
          className="overflow_stepper"
          sx={{
            overflow: "auto",
          }}
        >
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            className="stepperClass"
            sx={{
              minWidth: "260px",
            }}
          >
            <Stepper
              color={themeOrange}
              fontSize="12px"
              fontColor="black"
              steps={[
                { label: "SCHEDULE" },
                { label: "JOB DETAILS" },
                { label: "CONFIRM" },
              ]}
              currentStep={3}
            />
          </Stack>
        </Stack>
        <Divider sx={{ marginTop: "50px" }} />
        {!userAuth ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={2}
          >
            <ButtonComp
              onClick={() => {
                navigate("/signup-user");
                localStorage.setItem(
                  SERVICE_DETAIL_PAGE_CONSTANT.from,
                  "FROM_SERVICE_DETAIL"
                );
                localStorage.setItem(
                  SERVICE_DETAIL_PAGE_CONSTANT.service_id,
                  id
                );
              }}
              label={common_caption_strings.Signup}
              style={{
                width: "50%",
                borderRadius: "15px",
                background: "linear-gradient(180deg, #F15A24 0%, #C53F10 100%)",
                margin: "20px 0px",
              }}
            />
            <ButtonComp
              onClick={() => {
                navigate("/login", { state: { user_role: 3 } });
                localStorage.setItem(
                  SERVICE_DETAIL_PAGE_CONSTANT.from,
                  "FROM_SERVICE_DETAIL"
                );
                localStorage.setItem(
                  SERVICE_DETAIL_PAGE_CONSTANT.service_id,
                  id
                );
              }}
              label={common_caption_strings.Login}
              style={{
                width: "50%",
                borderRadius: "15px",
                background: "linear-gradient(180deg, #F15A24 0%, #C53F10 100%)",
                margin: "20px 0px",
              }}
            />
          </Stack>
        ) : (
          <Stack>
            <Stack mt={4}>
              <Stack flexDirection={"row"} alignItems={"center"} gap={"9px"}>
                <Stack>
                  <img
                    width={"40px"}
                    height={"40px"}
                    src={serName}
                    alt="Service Name"
                  />
                </Stack>
                <Typography className="Service-Name-head">
                  {get_service_by_id_data?.service?.name}
                </Typography>
              </Stack>
              {date?.length > 0 && (
                <Stack ml={1}>
                  {date?.map(({ selected_date, shifts }) => {
                    return (
                      <Stack
                        mt={2}
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={2}
                      >
                        <Stack>
                          {/* <FaCalendarDays color={themeOrange} size={26} /> */}
                          <LuCalendarClock
                            style={{
                              color: themeOrange,
                              fontSize: "26px",
                              flexShrink: "0",
                            }}
                          />
                        </Stack>
                        <Typography className="Service-Name-list">
                          {format(selected_date, "EEE, MMM d, yyyy")}
                        </Typography>
                        <Typography className="service_shifts_txt">
                          (
                          {shifts?.map((shift) => shift.slice(0, 3)).join(", ")}
                          )
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
              {location && (
                <Stack
                  mt={2}
                  ml={1}
                  flexDirection={"row"}
                  alignItems={"center"}
                  gap={2}
                >
                  <Stack>
                    <FaLocationDot color={themeOrange} size={26} />
                  </Stack>
                  <Typography className="Service-Name-list">
                    {location === "undefined" ? "" : location}
                  </Typography>
                </Stack>
              )}
              {desc && (
                <Stack
                  mt={2}
                  ml={1}
                  flexDirection={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                  gap={2}
                >
                  <Stack>
                    <IoDocumentTextSharp color={themeOrange} size={26} />
                  </Stack>
                  <Typography className="Service-Name-list">
                    {desc || ""}
                  </Typography>
                </Stack>
              )}
              {time && (
                <Stack
                  mt={2}
                  ml={1}
                  flexDirection={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                  gap={2}
                >
                  <Stack>
                    <MdOutlineWatchLater color={themeOrange} size={26} />
                  </Stack>
                  <Typography className="Service-Name-list">
                    {time || ""}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Divider sx={{ marginTop: "50px" }} />
            <Stack my={3}>
              <Typography mt={5} className="heading-name-text">
                Service Rate
              </Typography>
              <Stack direction={"row"} alignItems={"center"} my={3} gap={1}>
                <BiSolidLock style={{ fontSize: "24px", color: themeOrange }} />{" "}
                <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
                  Credit Card
                </Typography>
              </Stack>
              <CardElement
                onChange={handleCardChange}
                className="stripeInput"
                options={{ hidePostalCode: true }}
              />
              {isCardComplete && (
                <Stack direction={"row"} alignItems={"center"} my={1} gap={1}>
                  <img
                    src={secure_check}
                    style={{
                      height: "20px",
                      width: "20px",
                      objectFit: "contain",
                    }}
                  />{" "}
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#666666",
                    }}
                  >
                    Secure Payment Processing
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Divider sx={{ marginTop: "20px" }} />
            <Stack mt={0.5} flexDirection={"row"} alignItems={"center"}>
              <Stack flex={1} className="service-list">
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: "500" }}
                >
                  Service Amount
                </Typography>
              </Stack>
              <Typography
                className="service-list number_family"
                sx={{
                  fontSize: "20px",
                  fontWeight: "700 !important",
                  color: `${themeOrange} !important`,
                }}
              >
                ${(+get_service_by_id_data?.service?.price)?.toLocaleString()}
              </Typography>
            </Stack>

            {price !== "user" && (
              <Stack mt={0.5} flexDirection={"row"} alignItems={"center"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  flex={1}
                  className="service-list"
                  gap={2}
                >
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: "500",
                      color: themeBlue,
                    }}
                  >
                    Done+ Member Discount{" "}
                  </Typography>
                  <Tooltip
                    title="Done+ Member get $25 off on every job."
                    placement="top"
                  >
                    <IconButton>
                      <FiInfo style={{ fontSize: "20px", color: "grey" }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Typography
                  className="service-list number_family"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "700 !important",
                    color: `${themeBlue} !important`,
                  }}
                >
                  - $ 25
                </Typography>
              </Stack>
            )}
            <Stack mt={0.5} flexDirection={"row"} alignItems={"center"}>
              <Stack flex={1} className="service-list">
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "500",
                    color: themeBlue,
                  }}
                >
                  HST (%13)
                </Typography>
              </Stack>
              <Typography
                className="service-list number_family"
                sx={{
                  fontSize: "20px",
                  fontWeight: "700 !important",
                  color: `${themeBlue} !important`,
                }}
              >
                ${formattedTotalPrice}
              </Typography>
            </Stack>

            <Stack mt={0.5} flexDirection={"row"} alignItems={"center"}>
              <Stack flex={1} className="service-list">
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: "500" }}
                >
                  Total Price
                </Typography>
              </Stack>
              <Typography
                className="service-list number_family"
                sx={{
                  fontSize: "20px",
                  fontWeight: "700 !important",
                  color: `${themeOrange} !important`,
                }}
              >
                $
                {price === "user"
                  ? (
                      +formattedTotalPrice +
                      get_service_by_id_data?.service?.price
                    ).toLocaleString()
                  : +formattedTotalPrice +
                      get_service_by_id_data?.service?.price -
                      25 || ""}
              </Typography>
            </Stack>
            <Divider sx={{ marginTop: "15px" }} />
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              onClick={handlePaymentSubmit}
              style={{
                background:
                  isCardComplete || jobPostLoader
                    ? "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))"
                    : "#E2E2E2", // Disable color
                color: isCardComplete || jobPostLoader ? "white" : "#B5B5B5",
                padding: "16px",
                borderRadius: "30px",
                textAlign: "center",
                cursor:
                  isCardComplete || jobPostLoader ? "pointer" : "not-allowed", // Disable cursor
              }}
              mt={3}
              disabled={!isCardComplete || jobPostLoader} // Disable button
            >
              {jobPostLoader ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                "Book Now"
              )}
            </Stack>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              mt={2}
              gap={2}
              mb={10}
            >
              <Stack
                onClick={handleEdit}
                flex={1}
                sx={{
                  background:
                    "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))", // Disable color
                  color: "white",
                  padding: "16px",
                  borderRadius: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                className="edit_job_btn"
              >
                Edit Job
              </Stack>

              {/* <CustomModal
            setIsOpen={() => setEditOpen(!editOpen)}
            isOpen={editOpen}
          >
            <Stack
              sx={{ height: "70vh", overflow: "auto" }}
              p={5}
              bgcolor={"white"}
              width={"100%"}
            >
              <Stack gap={3}>
                <IconInput
                  id="date"
                  placeholder="Add Date"
                  first_icon={<FaCalendarDays size={32} color={themeOrange} />}
                  style={{ width: "100%", paddingLeft: "60px" }}
                  onChange={handleInputChange}
                  defaultValue={date}
                />

                <CustomLocationSearch
                  textCondition={false}
                  defaultValue={localStorage.getItem(
                    service_booking_keys.LOCATION
                  )}
                  setSelectedLocationProps={(location) =>
                    setEditObj({ ...editObj, location })
                  }
                />

                <textarea
                  id="time"
                  rows="2"
                  cols="20"
                  placeholder="Enter time."
                  defaultValue={time}
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "7px",
                    padding: "10px",
                    resize: "none",
                  }}
                ></textarea>

                <textarea
                  id="descEdit"
                  rows="4"
                  cols="50"
                  defaultValue={desc}
                  placeholder="Enter description..."
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "7px",
                    padding: "10px",
                    resize: "none",
                  }}
                ></textarea>

                <Stack flexDirection={"row"} alignItems={"center"} gap={3}>
                  <Stack
                    onClick={() => setEditOpen(!editOpen)}
                    flex={1}
                    style={{
                      border: `1px solid ${themeOrange}`,
                      color: themeOrange,
                      padding: "16px",
                      borderRadius: "30px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    Cancel
                  </Stack>
                  <Stack
                    onClick={handleEditUpdate}
                    flex={1}
                    style={{
                      backgroundColor: themeOrange,
                      color: "white",
                      padding: "16px",
                      borderRadius: "30px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    Update
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </CustomModal> */}

              <Stack
                onClick={_handleCancelJob}
                flex={1}
                sx={{
                  border: `1px solid ${themeOrange}`,
                  color: themeOrange,
                  padding: "16px",
                  borderRadius: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                className="cancel_btn"
              >
                Cancel Job
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

const WrappedLastStep = () => (
  <Elements stripe={stripePromise}>
    <LastStep />
  </Elements>
);

export default WrappedLastStep;
