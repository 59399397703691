import React from "react";
import css from "./VendorDashboard.module.css";
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetJobByIdAsync } from "../../services/services";
import { asyncStatus } from "../../utils/asyncStatus";
import { themeOrange } from "../../utils/colorTheme";
import { imgUrl } from "../../utils/imageUrl";
import { LuCalendarClock } from "react-icons/lu";
import { MdOutlineLocationOn, MdOutlineWatchLater } from "react-icons/md";
import { format } from "date-fns";

const JobDoneDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { get_job_by_id_error, get_job_by_id_data, get_job_by_id_status } =
    useSelector((state) => state.serviceManage);

  useEffect(() => {
    dispatch(GetJobByIdAsync(id));
  }, []);
  const loading = get_job_by_id_status === asyncStatus.LOADING;

  console.log(get_job_by_id_data || "");
  let order = get_job_by_id_data?.order;

  return (
    <Container maxWidth="xl">
      <Stack gap={3}>
        <Stack>
          <Typography variant="caption" className={css.total}>
            Job{" "}
            <Typography variant="caption" className={css.main_heading}>
              Details
            </Typography>
          </Typography>
          <hr color={"#D1D1D1"} />
        </Stack>
        {/* *
        
        
        
        */}
        {loading ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "60vh", width: "100%" }}
          >
            <CircularProgress size={30} sx={{ color: themeOrange }} />
          </Stack>
        ) : (
          <Stack
            sx={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: "15px",
            }}
          >
            <Stack
              className="globleGradientBlue"
              sx={{
                borderRadius: "15px",
                py: 1.5,
                px: 2,
                boxShadow: "none",
              }}
            >
              <Typography className="secondSubHeading" sx={{ color: "white" }}>
                {order?.service?.name || ""}
              </Typography>
            </Stack>

            {/* {JSON.stringify(order)} */}
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ px: 2, py: 2 }}
            >
              <Stack
                direction={"row"}
                alignItems={"flex-start"}
                gap={2}
                sx={{ width: "100%" }}
              >
                <Stack
                  pb={1.5}
                  gap={1}
                  sx={{
                    borderRadius: "10px",
                    width: "150px",
                    backgroundColor: "#F5F5F5",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                >
                  <Stack sx={{ height: "70px", width: "100%" }}>
                    <img
                      src={`${imgUrl}${order?.service?.mobile_image}`}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Stack>
                  <Typography
                    className="mainPara"
                    sx={{
                      color: "#333333",
                      mx: 1,
                      textAlign: "center",
                    }}
                  >
                    {order?.service?.name || ""}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{
                    width: "100%",
                    height: "auto",
                  }}
                  flexWrap={{
                    xl: "nowrap",
                    lg: "nowrap",
                    md: "wrap",
                    sm: "wrap",
                    xs: "wrap",
                  }}
                >
                  <Stack
                    justifyContent={"space-around"}
                    gap={2}
                    sx={{ height: "100%" }}
                  >
                    {/* <Stack sx={{ height: "100%" }}>
                      <Stack
                        mt={0.5}
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={2}
                      >
                        <Stack>
                          <LuCalendarClock
                            style={{
                              color: themeOrange,
                              fontSize: "26px",
                              flexShrink: "0",
                            }}
                          />
                        </Stack>
                        <Typography
                          className="subpara"
                          sx={{ color: "#333333" }}
                        >
                          {format(
                            order?.date_time_lock?.date,
                            "EEE, MMM d, yyyy"
                          )}
                        </Typography>
                        <Typography className="service_shifts_txt">
                          ({order?.date_time_lock?.shift})
                          (
                              {shifts
                                ?.map((shift) => shift?.slice(0, 3))
                                .join(", ")}
                              )
                        </Typography>
                      </Stack>
                    </Stack> */}

                    <Stack sx={{ height: "100%" }}>
                      {Array.isArray(order?.date) &&
                        order?.date?.map(({ selected_date, shifts }) => {
                          return (
                            <Stack
                              mt={0.5}
                              flexDirection={"row"}
                              alignItems={"center"}
                              gap={2}
                            >
                              <Stack>
                                {/* <FaCalendarDays color={themeOrange} size={26} /> */}
                                <LuCalendarClock
                                  style={{
                                    color: themeOrange,
                                    fontSize: "26px",
                                    flexShrink: "0",
                                  }}
                                />
                              </Stack>
                              <Typography
                                className="subpara"
                                sx={{ color: "#333333" }}
                              >
                                {format(selected_date, "EEE, MMM d, yyyy")}
                              </Typography>
                              <Typography className="service_shifts_txt">
                                (
                                {shifts
                                  ?.map((shift) => shift?.slice(0, 3))
                                  .join(", ")}
                                )
                              </Typography>
                            </Stack>
                          );
                        })}
                    </Stack>
                    {order?.time && (
                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        justifyContent={"flex-start"}
                        gap={1}
                      >
                        <MdOutlineWatchLater
                          style={{
                            fontSize: "24px",
                            color: "#F15A24",
                            flexShrink: 0,
                          }}
                        />
                        <Typography
                          className="subpara"
                          sx={{ color: "#333333" }}
                        >
                          {order?.time?.length > 30
                            ? `${order?.time?.substring(0, 30)}...`
                            : order?.time}
                        </Typography>
                      </Stack>
                    )}
                    <Stack
                      direction={"row"}
                      alignItems={"flex-start"}
                      justifyContent={"flex-start"}
                      gap={1}
                    >
                      <MdOutlineLocationOn
                        style={{
                          fontSize: "24px",
                          color: "#F15A24",
                          flexShrink: 0,
                        }}
                      />
                      <Typography className="subpara" sx={{ color: "#333333" }}>
                        {order?.location || ""}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "15px",
                px: 1,
              }}
            >
              <Stack gap={1} px={2} py={2}>
                <Typography
                  className="mainHeading"
                  sx={{ color: "#333333", fontSize: "20px" }}
                >
                  Customer Details
                </Typography>
                <Stack
                  sx={{
                    borderRadius: "15px",
                    border: "1px solid #F1F1F1",
                  }}
                  px={2}
                  py={2}
                >
                  <Typography className="mainPara" sx={{ color: "#868181" }}>
                    {order?.user?.first_name || ""}{" "}
                    {order?.user?.last_name || ""}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    borderRadius: "15px",
                    border: "1px solid #F1F1F1",
                  }}
                  px={2}
                  py={2}
                >
                  <Typography className="mainPara" sx={{ color: "#868181" }}>
                    {order?.user?.email || ""}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    borderRadius: "15px",
                    border: "1px solid #F1F1F1",
                  }}
                  px={2}
                  py={2}
                >
                  <Typography className="mainPara" sx={{ color: "#868181" }}>
                    {order?.user?.phone || ""}
                  </Typography>
                </Stack>
                {order?.user?.address_line_1 && (
                  <Stack
                    sx={{
                      borderRadius: "15px",
                      border: "1px solid #F1F1F1",
                    }}
                    px={2}
                    py={2}
                  >
                    <Typography className="mainPara" sx={{ color: "#868181" }}>
                      {order?.user?.address_line_1 || ""}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>

            <Stack
              sx={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "15px",
                px: 1,
              }}
            >
              {/* Job Description */}
              {order?.description && (
                <Stack gap={1} px={2} py={2}>
                  <Typography
                    className="mainHeading"
                    sx={{ color: "#333333", fontSize: "20px" }}
                  >
                    Job Description
                  </Typography>
                  <Stack
                    sx={{
                      borderRadius: "15px",
                      border: "1px solid #F1F1F1",
                    }}
                    px={2}
                    py={2}
                  >
                    <Typography className="mainPara" sx={{ color: "#868181" }}>
                      {order?.description || ""}
                    </Typography>
                  </Stack>
                </Stack>
              )}

              {/* Timing Constraints */}
              {order?.time && (
                <Stack gap={1} px={2} py={2}>
                  <Typography
                    className="mainHeading"
                    sx={{ color: "#333333", fontSize: "20px" }}
                  >
                    Timing Constraints
                  </Typography>
                  <Stack
                    sx={{
                      borderRadius: "15px",
                      border: "1px solid #F1F1F1",
                    }}
                    px={2}
                    py={2}
                  >
                    <Typography
                      className="mainPara"
                      sx={{
                        color: "#868181",
                        textAlign: "start",
                      }}
                    >
                      {order?.time || ""}
                    </Typography>
                  </Stack>
                </Stack>
              )}

              {order?.vendor?.first_name && (
                <Stack gap={1} px={2} py={2}>
                  <Typography
                    className="mainHeading"
                    sx={{ color: "#333333", fontSize: "20px" }}
                  >
                    Vendor Detail
                  </Typography>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                      borderRadius: "15px",
                      border: "1px solid #F1F1F1",
                    }}
                    px={2}
                    py={2}
                  >
                    <Stack>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Typography
                          className="mainPara"
                          sx={{
                            color: "black",
                            fontWeight: "600 !important",
                            fontSize: "16px !important",
                          }}
                        >
                          Name :
                        </Typography>
                        <Typography
                          className="mainPara"
                          sx={{ color: "#868181" }}
                        >
                          {`${order?.vendor?.first_name || ""} ${
                            order?.vendor?.last_name || ""
                          }`}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Typography
                          className="mainPara"
                          sx={{
                            color: "black",
                            fontWeight: "600 !important",
                            fontSize: "16px !important",
                          }}
                        >
                          Email :
                        </Typography>
                        <Typography
                          // className="mainPara"
                          sx={{ color: "#868181" }}
                        >
                          {`${order?.vendor?.email || ""} `}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}

              {/* Multiple Images */}
              {/* <Stack
                gap={1}
                className="jobs_images_scrollbar_wrapper"
                direction={"row"}
                alignItems={"center"}
                sx={{
                  mx: 2,
                }}
              ></Stack> */}
              {/* Multiple Images */}
              {order?.images?.length > 0 && (
                <Stack px={2} py={2} sx={{ width: "100%" }}>
                  {order?.images?.map(({ url, caption }) => {
                    return (
                      <Stack
                        key={url}
                        sx={{
                          border: "1px solid #F1F1F1",
                          borderRadius: "20px",
                          padding: "10px",
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <Stack
                              sx={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "rgb(238, 236, 236)",
                                borderRadius: "10px",
                              }}
                            >
                              <img
                                src={url}
                                style={{
                                  width: "100%",
                                  objectFit: "contain",
                                  height: "120px",
                                  borderRadius: "10px",
                                }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                            <Stack>
                              <Typography
                                className="mainPara"
                                sx={{
                                  // border: "0.6px solid #F1F1F1",
                                  // padding: "10px",
                                  // borderRadius: "20px",
                                  color: "gray",
                                }}
                              >
                                {caption || ""}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
              {/* <Grid container spacing={2}>
                {order?.images?.map((image, i) => {
                 
                  return (
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                      <Stack
                        key={i}
                        direction={"row"}
                        // alignItems={"center"}
                        sx={{
                          height: "100%",
                          border: "1px solid #e0e0e0",
                          borderRadius: "15px",
                        }}
                        gap={2}
                        p={1}
                      >
                        <img
                          src={image?.url}
                          style={{
                            height: "105px",
                            width: "105px",
                            borderRadius: "15px",
                            objectFit: "contain",
                          }}
                        />
                        <Typography
                          className="mainPara"
                          sx={{
                            color: "black",
                            textAlign: "start",
                            // my:2
                          }}
                        >
                          {image?.caption || ""}
                        </Typography>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid> */}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default JobDoneDetails;
