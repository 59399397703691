import React, { useEffect, useState } from "react";
import { themeOrange } from "../../utils/colorTheme";
import Stepper from "react-stepper-js";
import "react-stepper-js/dist/index.css";
import { Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import "./index.css";
import { FaCalendarDays } from "react-icons/fa6";
import { FiPlus } from "react-icons/fi";
import IconInput from "../IconInput/IconInput";
import { useDispatch, useSelector } from "react-redux";
import { setJobsSteps } from "../../store/slices/ui_control_slice";
import { service_booking_keys } from "../../store/constants";
import { ErrorMessage, Form, Formik } from "formik";
import ButtonComp from "../common/ButtonComp";
import { getValidationSchema } from "../../pages/auth/ValidationsFormikYup/ValidationsSchema";
import { LuCalendarClock } from "react-icons/lu";
import { PiPlusBold } from "react-icons/pi";
import CustomModal from "../common/CustomModal/CustomModal";
import { RxCross2 } from "react-icons/rx";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import ShiftsSelector from "../ShiftsSelector/ShiftsSelector";
import { format } from "date-fns";
import { TiDelete } from "react-icons/ti";
import { IoIosWarning } from "react-icons/io";
import { MdInfoOutline } from "react-icons/md";

const FirstStep = () => {
  const dispatch = useDispatch();
  const [openSelectDateRangeModal, setOpenSelectDateRangeModal] =
    useState(false);
  const [dates, setDates] = useState([]); // To store selected dates
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [shiftsByDate, setShiftsByDate] = useState({}); // Map of dates to selected shifts
  const [selectedDatesShifts, setSelectedDatesShifts] = useState([]); // The currently active date for shift selection

  // Set initial values from localStorage or fallback to empty strings
  const initialValues = {
    date: selectedDatesShifts.map((item) => item),
    time: localStorage.getItem(service_booking_keys.TIME) || "",
  };

  console.log("initial values", initialValues);

  // Load initial state from localStorage
  useEffect(() => {
    const storedDates = JSON.parse(
      localStorage.getItem(service_booking_keys.DATE)
    );
    if (storedDates) {
      setSelectedDatesShifts(storedDates); // Load saved data into state
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { get_service_by_id_data } = useSelector(
    (state) => state.serviceManage
  );

  const _handleNext = (values) => {
    console.log("values", values);
    dispatch(
      setJobsSteps({
        value: "2",
      })
    );
    // Store form data into localStorage
    localStorage.setItem(
      service_booking_keys.DATE,
      JSON.stringify(values.date)
    );
    localStorage.setItem(service_booking_keys.TIME, values.time.trim());
  };

  // Function to handle date selection and deselection
  const handleDateSelection = (selectedDates) => {
    const updatedShiftsByDate = { ...shiftsByDate };

    // Remove deselected dates
    Object.keys(updatedShiftsByDate).forEach((date) => {
      if (!selectedDates.some((d) => d?.toISOString() === date)) {
        delete updatedShiftsByDate[date];
      }
    });

    setDates(selectedDates);
  };

  useEffect(() => {
    const checkIfAllShiftsPassed = () => {
      const now = new Date();
      const shifts = [
        { label: "Morning", time: "09:00 AM" },
        { label: "Afternoon", time: "01:00 PM" },
        { label: "Evening", time: "06:00 PM" },
      ];

      // Check if all shifts for today's date have passed
      return shifts.every(({ time }) => {
        const [hours, minutesPart] = time.split(":");
        const minutes = parseInt(minutesPart.slice(0, 2));
        const ampm = time.slice(-2);
        const shiftDate = new Date();

        // Convert 12-hour time to 24-hour time
        shiftDate.setHours(
          ampm === "PM" && hours !== "12"
            ? parseInt(hours) + 12
            : parseInt(hours)
        );
        shiftDate.setMinutes(minutes);
        shiftDate.setSeconds(0);
        shiftDate.setMilliseconds(0);

        return now > shiftDate; // Return true if the shift has passed
      });
    };

    if (dates.length === 0) {
      // If no dates are selected, clear selected shifts
      setSelectedShifts([]);
    } else if (dates.length === 1) {
      const selectedDate = new Date(dates[0]);
      const today = new Date().toDateString();
      const isToday = selectedDate.toDateString() === today;

      if (isToday) {
        // If the selected date is today, check if all shifts have passed
        const allShiftsPassed = checkIfAllShiftsPassed();
        if (allShiftsPassed) {
          setSelectedShifts([]);
        }
      }
    }
  }, [dates]);

  // Function to handle shift selection
  const handleShiftSelect = (shifts) => {
    setSelectedShifts(shifts);

    // Update shifts for all currently selected dates
    const updatedShiftsByDate = { ...shiftsByDate };
    dates.forEach((date) => {
      const formattedDate = date.toISOString();
      updatedShiftsByDate[formattedDate] = shifts;
    });

    setShiftsByDate(updatedShiftsByDate);
  };

  const shiftsTimings = [
    { label: "Morning", time: "09:00 AM" },
    { label: "Afternoon", time: "01:00 PM" },
    { label: "Evening", time: "06:00 PM" },
  ];

  // const now = new Date("2024-11-22T13:16:00");

  const _handleSubmit = () => {
    const now = new Date();
    // const now = new Date("2024-11-22T13:16:00");

    // Helper function to check if a shift has passed
    const hasShiftPassed = (shiftTime) => {
      const [hours, minutesPart] = shiftTime.split(":");
      const minutes = parseInt(minutesPart.slice(0, 2));
      const ampm = shiftTime.slice(-2);
      const shiftDate = new Date();

      shiftDate.setHours(
        ampm === "PM" && hours !== "12" ? parseInt(hours) + 12 : parseInt(hours)
      );
      shiftDate.setMinutes(minutes);
      shiftDate.setSeconds(0);
      shiftDate.setMilliseconds(0);

      return now > shiftDate; // Return true if the shift has passed
    };

    // Create a copy of selectedDatesShifts to avoid direct mutation
    const updatedSelectedDatesShifts = dates.reduce((acc, date) => {
      const formattedDate = date.toISOString();
      const isToday =
        new Date().toDateString() === new Date(date).toDateString();

      // Filter shifts for today's date to exclude passed shifts
      const filteredShifts = isToday
        ? selectedShifts.filter((shift) => {
            const shiftObject = shiftsTimings.find((s) => s.label === shift);
            return shiftObject && !hasShiftPassed(shiftObject.time);
          })
        : selectedShifts;

      // Check if the date already exists in selectedDatesShifts
      const existingEntry = selectedDatesShifts.find(
        (entry) => entry.selected_date === formattedDate
      );

      // If the date doesn't exist in selectedDatesShifts, add the new date and shifts
      if (!existingEntry) {
        acc.push({
          selected_date: formattedDate,
          shifts: [...filteredShifts], // Add only valid shifts
        });
      }

      return acc;
    }, []);

    // Set the updated selected dates and shifts
    setSelectedDatesShifts((prev) => [...prev, ...updatedSelectedDatesShifts]);

    setOpenSelectDateRangeModal(false);
  };

  const handleUpdateShifts = (updatedShifts, selectedDate) => {
    // Update the shifts for a specific date in the selectedDatesShifts state
    setSelectedDatesShifts((prev) =>
      prev.map((entry) =>
        entry.selected_date === selectedDate
          ? { ...entry, shifts: updatedShifts }
          : entry
      )
    );
  };

  const _handleDeleteSelecteDateShifts = (selected_date) => {
    setSelectedDatesShifts((prev) =>
      prev.filter((entry) => entry.selected_date !== selected_date)
    );
    setDates((prev) =>
      prev.filter((date) => date.toISOString() !== selected_date)
    );
  };

  const disabledDatesArray = selectedDatesShifts.map(
    ({ selected_date }) => new Date(selected_date)
  );

  return (
    <div>
      <Stack sx={{ overflow: "hidden" }}>
        <Stack className="service-name">
          {get_service_by_id_data?.service?.name}
        </Stack>
        <Stack
          className="overflow_stepper"
          sx={{
            overflow: "auto",
          }}
        >
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            className="stepperClass"
            sx={{
              minWidth: "260px",
            }}
          >
            <Stepper
              color={themeOrange}
              fontSize="12px"
              fontColor="black"
              steps={[
                { label: "SCHEDULE" },
                { label: "JOB DETAILS" },
                { label: "CONFIRM" },
              ]}
              currentStep={1}
            />
          </Stack>
        </Stack>

        <Stack>
          <Divider sx={{ marginTop: "50px" }} />
          <Stack mt={3} className="service-sub-name">
            When should we send someone?
          </Stack>

          <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema("second_step")}
            onSubmit={_handleNext}
            enableReinitialize // Important for dynamic field updates
          >
            {({
              errors,
              touched,
              setFieldValue,
              setFieldError,
              values,
              handleSubmit,
            }) => {
              const indicesWithEmptyShifts = values?.date?.reduce(
                (acc, obj, index) =>
                  obj.shifts.length === 0 ? [...acc, index] : acc,
                []
              );
              const handleNextButtonClick = () => {
                if (values.date.length === 0) {
                  setFieldError("date", "Please select at least one date.");
                } else {
                  values.date.forEach((date, index) => {
                    const shiftsForDate = date.shifts || [];

                    if (shiftsForDate.length === 0) {
                      setFieldError(
                        `shifts`,
                        "Please choose a time for this date."
                      );
                    }
                  });
                }
                if (Object.keys(errors).length === 0) {
                  // If there are no errors, proceed to the next step
                  handleSubmit();
                }
              };

              return (
                <Form>
                  <Stack gap={3}>
                    <Stack gap={2} my={3}>
                      {selectedDatesShifts.length > 0 &&
                        selectedDatesShifts.map(
                          ({ selected_date, shifts }, index) => {
                            const readableDate = format(
                              new Date(selected_date),
                              "EEE, MMM d, yyyy"
                            );

                            return (
                              <>
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems={"stretch"}
                                >
                                  <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    md={12}
                                    sm={4}
                                    xs={12}
                                  >
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                      sx={{
                                        padding: "10px",
                                        border: `1px solid ${themeOrange}`,
                                        paddingLeft: "8px",
                                        borderRadius: "5px",
                                        margin: "0px",
                                        borderColor: themeOrange,
                                      }}
                                    >
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={2}
                                        sx={{
                                          // width: "15vw",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <LuCalendarClock
                                          style={{
                                            color: themeOrange,
                                            fontSize: "20px",
                                            flexShrink: "0",
                                          }}
                                        />
                                        <Typography
                                          sx={{ whiteSpace: "nowrap" }}
                                        >
                                          {readableDate}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </Grid>

                                  <Grid
                                    item
                                    xl={8}
                                    lg={8}
                                    md={12}
                                    sm={8}
                                    xs={12}
                                  >
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                      sx={{ height: "100%" }}
                                      gap={2}
                                    >
                                      <ShiftsSelector
                                        selectedDate={new Date(selected_date)} // activeDate is passed as a Date object
                                        onShiftSelect={(_, updatedShifts) => {
                                          {
                                            handleUpdateShifts(
                                              updatedShifts,
                                              selected_date
                                            );
                                            const updatedDates = [
                                              ...values.date,
                                            ];
                                            updatedDates[index].shifts =
                                              updatedShifts;
                                            setFieldValue("date", updatedDates);
                                          }
                                        }}
                                        initialShifts={shifts || []}
                                        allDates={selectedDatesShifts.map(
                                          ({ selected_date }) =>
                                            new Date(selected_date)
                                        )}
                                        spacing={{
                                          xl: 5,
                                          lg: 5,
                                          md: 5,
                                          sm: 3,
                                          xs: 2,
                                        }}
                                        isModal={false}
                                      />
                                      <IconButton
                                        onClick={() => {
                                          // arrayHelpers.remove(index);
                                          _handleDeleteSelecteDateShifts(
                                            selected_date
                                          );
                                        }}
                                      >
                                        <TiDelete
                                          style={{
                                            color: themeOrange,
                                            fontSize: "30px",
                                            flexShrink: "0",
                                          }}
                                        />
                                      </IconButton>
                                    </Stack>
                                  </Grid>
                                </Grid>
                                {errors.date &&
                                  errors.date[index] &&
                                  errors.date[index].shifts && (
                                    <Typography
                                      variant="caption"
                                      sx={{ color: "red", fontSize: "12px" }}
                                    >
                                      {errors.date[index].shifts}
                                    </Typography>
                                  )}
                              </>
                            );
                          }
                        )}
                    </Stack>

                    <Stack gap={1}>
                      <Stack mt={1} className="availible-date">
                        Select available date and times
                      </Stack>
                      <Grid container>
                        <Grid item xl={4} lg={4} md={12} sm={4} xs={12}>
                          <Stack sx={{ width: "100%" }}>
                            {/* <IconInput
                        id={"date"}
                        // disabled={true}
                        placeholder="Add Date"
                        first_icon={
                          <FaCalendarDays size={25} color={themeOrange} />
                        }
                        // second_icon={<FiPlus size={32} color={themeOrange} />}
                        style={{
                          width: "100%",
                          padding: "14px",
                          paddingLeft: "60px",
                          borderRadius: "10px",
                          margin: "0px",
                          borderColor:
                            touched.date && errors.date ? "red" : themeOrange,
                        }}
                        onChange={(e) => {
                          setFieldValue("date", e.target.value);
                        }}
                        value={values.date}
                        defaultValue={values.date}
                        // type={"date"}
                      /> */}

                            <Stack
                              onClick={() => {
                                setOpenSelectDateRangeModal(
                                  !openSelectDateRangeModal
                                );
                                setDates(
                                  selectedDatesShifts.map(
                                    ({ selected_date }) =>
                                      new Date(selected_date)
                                  )
                                );
                              }}
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              sx={{
                                // width: {
                                //   xl: "fit-content",
                                //   lg: "fit-content",
                                //   md: "fit-content",
                                //   sm: "fit-content",
                                //   xs: "auto",
                                // },
                                padding: "10px",
                                border: `1px solid ${themeOrange}`,
                                paddingLeft: "8px",
                                borderRadius: "5px",
                                margin: "0px",
                                borderColor:
                                  touched.date && errors.date
                                    ? "red"
                                    : themeOrange,
                                cursor: "pointer",
                              }}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                gap={2}
                                // sx={{
                                //   width: {
                                //     xl: "13.5vw",
                                //     lg: "13.5vw",
                                //     md: "13.5vw",
                                //     sm: "21vw",
                                //     xs: "stretch",
                                //   },
                                // }}
                              >
                                <LuCalendarClock
                                  style={{
                                    color: themeOrange,
                                    fontSize: "20px",
                                    flexShrink: "0",
                                  }}
                                />
                                <Typography sx={{ whiteSpace: "nowrap" }}>
                                  Add date
                                </Typography>
                              </Stack>
                              <PiPlusBold
                                style={{
                                  color: themeOrange,
                                  fontSize: "20px",
                                  flexShrink: "0",
                                }}
                              />
                            </Stack>

                            {errors.date && !Array.isArray(errors.date) && (
                              <Typography
                                variant="caption"
                                className="error-message"
                                sx={{ color: "red", fontSize: "12px" }}
                              >
                                {errors.date}
                              </Typography>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>

                    <Stack gap={1}>
                      <Stack className="Timing-constraints">
                        Timing constraints
                      </Stack>
                      <Stack>
                        <textarea
                          defaultValue={values.time}
                          id={"time"}
                          rows="4"
                          cols="50"
                          placeholder="e.g.Baby is napping from 3-4pm. Please do not arrive during those times."
                          value={values.time}
                          onChange={(e) =>
                            setFieldValue("time", e.target.value)
                          }
                          className="time_contraints_inp"
                          style={{
                            borderColor:
                              touched.time && errors.time ? "red" : themeOrange,
                            borderRadius: "5px",
                            padding: "12px 20px",
                            resize: "none",
                          }}
                        ></textarea>
                        <ErrorMessage
                          name="time"
                          component="div"
                          className="error-message"
                        />
                      </Stack>
                    </Stack>
                  </Stack>

                  <Divider sx={{ marginTop: "50px" }} />

                  <ButtonComp
                    // onClick={handleSubmit}
                    onClick={handleNextButtonClick}
                    type="submit"
                    label={"Next"}
                    style={{
                      width: "40%",
                      borderRadius: "15px",
                      background:
                        "linear-gradient(180deg, #F15A24 0%, #C53F10 100%)",
                      margin: "20px 0px",
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </Stack>
      </Stack>

      {/* <!--------- Date Range Modal ---------!> */}
      <CustomModal
        isOpen={openSelectDateRangeModal}
        setIsOpen={() => setOpenSelectDateRangeModal(!openSelectDateRangeModal)}
        modal_inner_Style={{
          width: {
            xl: "auto !important",
            lg: "auto !important",
            md: "auto !important",
            sm: "auto !important",
            xs: "85vw !important",
          },
        }}
      >
        <Stack
          sx={{
            overflow: "auto",
            // background: "linear-gradient(180deg, #273350, #2f4f94)",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
          alignItems={"center"}
          p={1}
        >
          <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
            <IconButton
              onClick={() =>
                setOpenSelectDateRangeModal(!openSelectDateRangeModal)
              }
            >
              <RxCross2 size={20} style={{ color: "black" }} />
            </IconButton>
          </Stack>

          <Stack gap={2} sx={{ width: "100%", padding: "35px 24px" }}>
            <Stack alignItems={"center"}>
              <DateRangePicker
                selectedDates={dates}
                onSelect={handleDateSelection}
                disabledDates={disabledDatesArray}
                disableAllDates={selectedDatesShifts?.length === 10}
                exceptDates={dates?.length === 10 ? dates : []}
              />
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              sx={{
                backgroundColor: "#ffe2d7",
                borderRadius: "8px",
                padding: "8px 12px",
                // width: "20vw",
              }}
              gap={1}
            >
              <Typography
                variant="caption"
                sx={{
                  color: "black",
                  fontSize: {
                    xl: "11px",
                    lg: "11px",
                    md: "11px",
                    sm: "8px",
                    xs: "8px",
                  },
                  fontWeight: "600",
                  lineHeight: "normal",
                  fontFamily: "Raleway",
                }}
              >
                We added a few more dates!{" "}
                <Typography
                  variant="caption"
                  sx={{
                    color: "black",
                    fontSize: {
                      xl: "11px",
                      lg: "11px",
                      md: "11px",
                      sm: "8px",
                      xs: "8px",
                    },
                    fontWeight: "400",
                    lineHeight: "normal",
                    fontFamily: "Raleway",
                  }}
                >
                  Add or remove <br /> dates by tapping on them
                </Typography>
              </Typography>
              <MdInfoOutline
                style={{ color: "black", fontSize: "20px", flexShrink: 0 }}
              />
            </Stack>
            {dates?.length === 10 && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "8px",
                  padding: "8px 12px",
                }}
                gap={1}
              >
                <IoIosWarning style={{ color: "black", fontSize: "15px" }} />
                <Typography
                  variant="caption"
                  sx={{
                    color: "black",
                    fontSize: {
                      xl: "11px",
                      lg: "11px",
                      md: "11px",
                      sm: "8px",
                      xs: "8px",
                    },
                    fontWeight: "400",
                    lineHeight: "normal",
                    fontFamily: "Raleway",
                  }}
                >
                  You’ve selected a maximum of 10 dates.
                </Typography>
              </Stack>
            )}

            {dates.length > 0 && selectedDatesShifts?.length < 10 && (
              <Formik
                initialValues={{ shift: [] }}
                validationSchema={getValidationSchema("dates_selection")}
                onSubmit={_handleSubmit}
              >
                {({ errors, touched, setFieldValue, values, handleSubmit }) => (
                  <Form>
                    <Stack gap={1} mb={1}>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "black",
                          fontSize: {
                            xl: "13px",
                            lg: "13px",
                            md: "13px",
                            sm: "11px",
                            xs: "11px",
                          },
                          fontWeight: "300",
                          lineHeight: "normal",
                          fontFamily: "Raleway",
                        }}
                      >
                        Select all available times
                      </Typography>
                      <ShiftsSelector
                        selectedDate={dates[dates.length - 1]} // activeDate is passed as a Date object
                        onShiftSelect={(_, shifts) => {
                          handleShiftSelect(shifts);
                          setFieldValue("shift", shifts);
                        }}
                        allDates={dates}
                        isModal={true} // Set true for modal
                      />
                      {touched.shift && errors.shift && (
                        <Typography
                          variant="caption"
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "4px",
                          }}
                        >
                          {errors.shift}
                        </Typography>
                      )}
                    </Stack>
                    <Stack gap={2}>
                      <ButtonComp
                        onClick={handleSubmit}
                        label={`Choose dates ${
                          dates?.length > 1 ? dates?.length : ""
                        }`}
                        className="member_ship_agree_continue_btn"
                      />
                    </Stack>
                  </Form>
                )}
              </Formik>
            )}
          </Stack>
        </Stack>
      </CustomModal>
    </div>
  );
};

export default FirstStep;
